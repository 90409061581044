import axios from 'axios'
import { getToken } from '../utils/auth'
import { removeToken, removeRole, setToken } from './auth'

const signOut = () => {
  removeToken()
  removeRole()
  window.open('/login', '_self')
}

const service = axios.create({
  baseURL: 'https://api.cloudbook.vn/api/v1',
})

// Example POST method implementation:
async function postData(url = '', data) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + data,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  return response.json() // parses JSON response into native JavaScript objects
}

// request interceptor
service.interceptors.request.use(
  (config) => {
    return new Promise((resolve, reject) => {
      config.headers['Content-Type'] = 'application/json'
      config.headers['Access-Control-Allow-Origin'] = '*'
      const token = getToken()
      if (token) {
        // config.headers['Authorization'] = 'Bearer ' + token
        postData(
          'https://api.cloudbook.vn/api/v1/auth-private/check-token',
          token,
        ).then((data) => {
          if (data.status === 'S0000') {
            config.headers['Authorization'] = 'Bearer ' + token

            // Or when you don't need an HTTP request just resolve
            resolve(config)
          } else {
            postData('https://api.cloudbook.vn/api/v1/auth/token/refresh', {
              refresh_token: localStorage.refresh_token,
            }).then((data) => {
              if (data.status === 'S0000') {
                config.headers['Authorization'] =
                  'Bearer ' + data.data.access_token
                setToken(data.data.access_token)
                localStorage.setItem('refresh_token', data.data.refresh_token)

                // Or when you don't need an HTTP request just resolve
                resolve(config)
              } else {
              }
            })
          }
        })
      } else {
        resolve(config)
      }
    })
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  },
)

service.interceptors.response.use(
  (response) => {
    if (response.data.status === 'UR0001') {
      signOut()
    }
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      signOut()
    }
    return Promise.reject(error)
  },
)

export default service
