import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Store from './Stores/GlobalContainer'
import { Subscribe } from 'unstated'
import { getToken } from './utils/auth'

const Logined = ({ component: Component, ...rest }) => {
  return (
    <Subscribe to={[Store]}>
      {store => (
        <Route
          {...rest}
          render={props => {
            const logined = store.state.token || getToken()
            return logined !== undefined ? (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
              />
            ) : (
              <Component {...props} />
            )
          }}
        />
      )}
    </Subscribe>
  )
}

export default Logined
