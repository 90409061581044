import React, { lazy } from 'react'
import ReactDOM from 'react-dom'
import 'react-notifications-component/dist/theme.css'
import './index.css'
import './App.scss'

import { Provider } from 'unstated'
import ErrorBoundary from './ErrorBoundary'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ReactNotification from 'react-notifications-component'
import PermissionRoute from './permission'
import Logined from './logined'

import * as serviceWorker from './serviceWorker'

const Login = lazy(() => import('./Views/Login'))
const Signup = lazy(() => import('./Views/Signup'))
const Editor = lazy(() => import('./Views/Editor'))
const Layout = lazy(() => import('./Views/Layout'))
const EpubReader = lazy(() => import('./Views/EpubReader'))
const Page404 = lazy(() => import('./Views/Pages/Page404'))
const Page500 = lazy(() => import('./Views/Pages/Page500'))

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">loading...</div>
)
const PharosApp = () => {
  return (
    <ErrorBoundary componentName="PharosApp">
      <Provider>
        <>
          {/* <GlobalStyle /> */}
          <React.Suspense fallback={() => loading()}>
            <ReactNotification />
            <Router>
              <Switch>
                <PermissionRoute
                  path={[
                    '/',
                    '/dashboard',
                    '/users',
                    '/users/*',
                    '/books',
                    '/books/*',
                    '/permission',
                    '/tools/*'
                  ]}
                  exact
                  name="Home"
                  roles={['ROLE_ADMIN']}
                  component={Layout}
                />
                <PermissionRoute
                  roles={['ROLE_ADMIN', 'ROLE_AUTHOR']}
                  exact
                  path="/editor"
                  name="Editor Page"
                  component={Editor}
                />
                <PermissionRoute
                  roles={['ROLE_ADMIN', 'ROLE_AUTHOR']}
                  exact
                  path="/epub"
                  name="Editor Page"
                  component={EpubReader}
                />
                <Logined
                  exact
                  path="/signup"
                  name="Signup"
                  component={Signup}
                />
                <Logined
                  exact
                  path="/login"
                  name="Login Page"
                  component={Login}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={props => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={props => <Page500 {...props} />}
                />
              </Switch>
            </Router>
          </React.Suspense>
        </>
      </Provider>
    </ErrorBoundary>
  )
}

ReactDOM.render(<PharosApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
