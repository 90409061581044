import { Container } from 'unstated'
import { login, getProfile } from '../api/user'
import { setToken, getToken, setRole } from '../utils/auth'

const ROLE_ACCESS = ['ROLE_ADMIN', 'ROLE_TEACHER']

class User extends Container {
  state = {
    email: '',
    name: '',
    token: '',
    role: '',
    avatar: '',
  }
  login(data, context) {
    const { email, password } = data
    return new Promise((resolve, reject) => {
      login({ email, password })
        .then((result) => {
          const { status } = result.data
          if (status === 'S0000') {
            const { email, name, role } = result.data.data.user
            const { access_token, picture_url } = result.data.data

            if (!ROLE_ACCESS.includes(role)) {
              alert('Bạn không phải là admin!')
            } else {
              this.setState({
                email: email,
                name: name,
                role: role,
                token: access_token,
                avatar: picture_url,
              })
              setToken(access_token)
              setRole(role)
              localStorage.setItem(
                'refresh_token',
                result.data.data.refresh_token,
              )
              context.history.push('/')
            }
          }
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getInfo() {
    const accessToken = getToken()
    if (accessToken) {
      getProfile().then((result) => {
        if (result.data.status === 'S0000') {
          const { email, name, role } = result.data.data.user
          const accessToken = result.data.data.access_token
          this.setState({
            email: email,
            name: name,
            role: role,
            token: accessToken,
            avatar: '',
          })
        }
      })
    }
    return {
      email: this.state.email,
      name: this.state.name,
      role: 'ROLE_ADMIN',
    }
  }
}

export default new User()
