import request from '../utils/request'
import Cookies from 'js-cookie'

export function login(data) {
  return request({
    url: '/auth/sign-in',
    method: 'post',
    data,
  })
}

export function signup(data) {
  return request({
    url: '/auth/sign-up',
    method: 'post',
    data,
  })
}

export function getProfile() {
  let req =  request({
    url: '/profile',
    method: 'get',
  }).then(data => data).catch(error => {
    Cookies.remove('Token')
    // window.location.pathname = "/login"
  })
  return req
}

export function logout(data) {
  return request({
    url: '/login/logout',
    method: 'post',
    data,
  })
}

export function getList(data) {
  return request({
    url: '/users',
    method: 'get',
    params: data,
  })
}

export function getInfoUser(email) {
  return request({
    url: `/users/look-up?username=${email}&page=0&size=10`,
    method: 'get',
  })
}

export function createAuthor(data) {
  return request({
    url: '/users',
    method: 'post',
    data,
  })
}

export function changePassword(data) {
  return request({
    url: '/profile/change-password',
    method: 'post',
    data,
  })
}

export function deleteUser(data) {
  return request({
    url: `/users/${data}`,
    method: 'delete',
  })
}

export function getListEmail() {
  return request({
    url: '/inventories/emails',
    method: 'get',
  })
}

export function uploadAvatar(data) {
  return request({
    url: '/profile/avatar',
    method: 'post',
    data,
  })
}

export function checkUserInformation(username) {
  return request({
    url: `/users/look-up?email=${username}`,
    method: 'GET',
  })
}

export function checkUserVerified(username) {
  return request({
    url: `/users/look-up/get-confirmation-url/?username=${username}`,
    method: 'GET',
  })
}

export function checkBookCode(code) {
  return request({
    url: `/admin/book-codes/look-up/users-by-code/${code}`,
    method: 'GET',
  })
}

export function deactivateBookCode(code) {
  return request({
    url: `/admin/book-codes/deactivate`,
    method: 'POST',
    data: JSON.stringify({
      code: code
    })
  })
}

export const getClassByClassId = (classId) => request({
  url: `/class/${classId}`,
  method: 'GET'
})

export const getUsersByClassId = (classId) => request({
  url: `/class/student/${classId}?size=99`,
  method: 'GET'
})

export const adminResetPassword = (data) => request({
  url: `/admin/users/set-password`,
  method: 'POST',
  data
})

export const adminUpdateProfileUser = (data) => request({
  url: `/admin/users/set-profile`,
  method: 'POST',
  data
})
