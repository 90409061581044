import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Store from './Stores/GlobalContainer'
import { Subscribe } from 'unstated'
import { getRole } from './utils/auth'

const PermissionRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Subscribe to={[Store]}>
      {(store) => (
        <Route
          {...rest}
          render={(props) => {
            const role = store.state.role || getRole
            return role !== undefined ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
          }}
        />
      )}
    </Subscribe>
  )
}

export default PermissionRoute
